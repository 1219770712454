import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import * as xlsx from "xlsx";

const ELEMENT_DATA: any[] = [
  {no: 1, name: 'Hydrogen', },
  {no: 2, name: 'Hydrogen', },
  {no: 3, name: 'Hydrogen', },
  {no: 5, name: 'Hydrogen', },

]
const FRUITS: string[] = [
  'blueberry', 'lychee', 'kiwi', 'mango', 'peach', 'lime', 'pomegranate', 'pineapple'
];
const NAMES: string[] = [
  'Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack', 'Charlotte', 'Theodore', 'Isla', 'Oliver',
  'Isabella', 'Jasper', 'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'
];
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {
  displayedColumns: string[] = ['documento','nombre','apellido','apellido','perfil','dependencia','area','email_personal','email_institucional','activo','acciones'];
  dataSource 
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  
  constructor() {
    this.dataSource = new MatTableDataSource(ELEMENT_DATA);

   }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  exportToExcel() {
    let newArray: any[] = [];
    let data = Object.values(this.dataSource.data);
    Object.keys(data).forEach((key, index) => {
      newArray.push({
        "Número de documento": data[key].name,
        "Nombres": data[key].name,
        "Apellidos": data[key].name,
        "Perfil o rol": data[key].name,
        "Dependencia/Facultad": data[key].name,
        "Área/Programas": data[key].name,
        "Email personal": data[key].name,
        "Email Institucional": data[key].name,
        "Activo": data[key].name,
       

      });
    });

    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(newArray);

    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    let date = new Date();
    xlsx.writeFile(
      wb,
      
        "COLMAYOR ADMINISTRADOR.xlsx"
    );
  }
}

function createNewUser(id: number): any {
  const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
    NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

  return {
    id: id.toString(),
    name: name,
    progress: Math.round(Math.random() * 100).toString(),
    fruit: FRUITS[Math.round(Math.random() * (FRUITS.length - 1))]
  };
}