import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { PromptComponent } from '../../@pages/components/prompt/prompt.component';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  public promptEvent: any;

  constructor(
    private bottomSheet: MatBottomSheet,
    private platform: Platform
  ) { }

  public initPwaPrompt() {
    if (this.platform.ANDROID) {
      if(this.promptEvent===undefined){
        //console.log("intentara")

        window.addEventListener('beforeinstallprompt', (event: any) => {
          //console.log("deja instalar")
          //console.log(event)

          event.preventDefault();
          this.promptEvent = event;
          this.openPromptComponent('android');
        });
      }else{
        //console.log("existe eveento",this.promptEvent)
        this.promptEvent.prompt();
      }     
     
    }
    if (this.platform.IOS) {
      const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      if (!isInStandaloneMode) {
        this.openPromptComponent('ios');
      }
    }
  }

  private openPromptComponent(mobileType: 'ios' | 'android') {
    timer(3000)
      .pipe(take(1))
      .subscribe(() => this.bottomSheet.open(PromptComponent, { data: { mobileType, promptEvent: this.promptEvent } }));
  }
}
