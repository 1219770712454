import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import frLocale from '@fullcalendar/core/locales/fr';
import { EventosService } from '../../services/eventos/eventos.service';
import { AlertService } from '../../services/alert/alert.service';
import { NavegacionService } from '../../services/navegacion/navegacion.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { PerfilService } from '../../services/perfil/perfil.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorService } from '../../services/error/error.service';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit {
  @ViewChild('fullcalendar', { static: true }) calendarComponent: FullCalendarComponent;

  public calendarOptions: any;

  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarEvents: EventInput[] = [
  ];

  eventosPropios = []

  buttonText: {
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    list: 'Lista'
  }

  options = { locales: [esLocale], }
  constructor(private httpClient: HttpClient,private errorService:ErrorService, private ngxUiLoaderService: NgxUiLoaderService, private perfilService: PerfilService, private eventoService: EventosService, private alertService: AlertService, private navegacionService: NavegacionService) {
    localStorage.setItem("obtenerInfo","true")

    try {
      this.ngxUiLoaderService.start()

      this.perfilService.obtenerEventos().subscribe(resp => {
        this.ngxUiLoaderService.stop()

        if (resp.success) {
          console.log(resp.data)
          this.eventosPropios = resp.data
          this.obtenerEventos()

        } else {
          this.obtenerEventos()

        }

      },error=>{
        this.obtenerEventos()

      })
      this.ngxUiLoaderService.stop()

    } catch (error) {
      this.ngxUiLoaderService.stop()

      this.obtenerEventos()
      this.errorService.notificarError(error.message).subscribe(resp=>{})

    }

  }

  ngOnInit() {
    this.calendarComponent.buttonText = this.buttonText
  }
  handleDateClick(calDate) {
    // //console.log(calDate);
  }

  eventClicked(calDate) {
    this.obtenerDetalleEvento(calDate.event.id)
  }


  obtenerEventos() {
    this.ngxUiLoaderService.start()
    this.eventoService.obtenerEventosDisponibles().subscribe(resp => {
      if (resp.success) {
        this.calendarEvents = []
        resp.data.publicados.forEach(evento => {

          if (this.eventosPropios.find(x => Number(x.id) === Number(evento.id)) != undefined) {
            this.calendarEvents.push({ title: "(Inscrito)"+evento.nombre, start: new Date(evento.fecha_hora_inicio), end : new Date(evento.fecha_hora_fin), id: evento.id, backgroundColor: "#0000ff", textColor : '#FFFFFF' })

          } else {

            this.calendarEvents.push({ title: evento.nombre, start: new Date(evento.fecha_hora_inicio), end : new Date(evento.fecha_hora_fin), id: evento.id, backgroundColor: evento.estilo_color_css })

          }
        });



        resp.data.en_ejecucion.forEach(evento => {
          if (this.eventosPropios.find(x => x.id === evento.id) != undefined) {
            this.calendarEvents.push({ title:"(Inscrito)"+ evento.nombre, start: new Date(evento.fecha_hora_inicio), end : new Date(evento.fecha_hora_fin), id: evento.id, backgroundColor: "#0000ff", textColor : '#FFFFFF' })

          } else {

            this.calendarEvents.push({ title: evento.nombre, start: new Date(evento.fecha_hora_inicio), end : new Date(evento.fecha_hora_fin), id: evento.id, backgroundColor: evento.estilo_color_css })

          }
        });

        resp.data.finalizados.forEach(evento => {
          if (this.eventosPropios.find(x => x.id === evento.id) != undefined) {
            this.calendarEvents.push({ title: "(Inscrito)"+evento.nombre, start: new Date(evento.fecha_hora_inicio), end : new Date(evento.fecha_hora_fin), id: evento.id, backgroundColor: "#0000ff", textColor : '#FFFFFF' })

          } else {

            this.calendarEvents.push({ title: evento.nombre, start: new Date(evento.fecha_hora_inicio), end : new Date(evento.fecha_hora_fin), id: evento.id, backgroundColor: evento.estilo_color_css, textColor : '#FFFFFF' })
          }
        });
        console.log(this.calendarEvents)

      } else {

        this.alertService.crearMensaje("errror", resp.message)
      }
      this.ngxUiLoaderService.stop()

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })


  }

  obtenerDetalleEvento(evento) {
   
        this.navegacionService.navegarDetalleEvento({id:evento})

  }
}

