import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Properties } from '../../../properties';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthService } from '../../../services/auth/auth.service';
import { ErrorService } from '../../../services/error/error.service';
import { NavegacionService } from '../../../services/navegacion/navegacion.service';

@Component({
  selector: 'app-olvide-clave',
  templateUrl: './olvide-clave.component.html',
  styleUrls: ['./olvide-clave.component.scss']
})
export class OlvideClaveComponent implements OnInit {

  form: FormGroup
  habilitarPaso2
  constructor(private navegacion: NavegacionService,private errorService:ErrorService, private _formBuilder: FormBuilder, private loginService: AuthService, private alertService: AlertService, private _cookieService: CookieService,) {

    this.form = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    }
    );
  }

  ngOnInit() {
   
  }

  recordar() {
    this.loginService.recordarClave(this.form.controls.email.value).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje('success', resp.message)
        this.habilitarPaso2=true
        this.form.addControl('otp', new FormControl('', Validators.required));
        this.form.addControl('clave', new FormControl('', Validators.required));
        this.form.controls.email.disable()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }
  cambiar() {
    this.loginService.cambiarClave(this.form.controls.email.value,this.form.controls.otp.value,this.form.controls.clave.value).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje('success', resp.message)
        this.navegarLogin()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }
  navegarLogin() {
    this.navegacion.navegarLogin()
  }
  navegarRegistro() {
    this.navegacion.navegarRegistro()
  }

  cancelar(){
    this.form.controls.email.enable()


    this.form = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    }
    );
    this.habilitarPaso2=false
    }
}
