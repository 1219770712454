import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthService } from '../../../services/auth/auth.service';
import { NavegacionService } from '../../../services/navegacion/navegacion.service';
import { BsLocaleService, BsModalRef } from 'ngx-bootstrap';
import { Properties } from '../../../properties';
import { MatGridTileHeaderCssMatStyler } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorService } from '../../../services/error/error.service';

@Component({
  selector: 'app-registro-datos',
  templateUrl: './registro-datos.component.html',
  styleUrls: ['./registro-datos.component.scss']
})
export class RegistroDatosComponent implements OnInit {
  form: FormGroup
  formOtp: FormGroup
  data
  modalRef?: BsModalRef;
  @ViewChild("exampleModalotp", { static: false }) modalOtp
  documentos
  documentoSeleccionado={titulo:"",pdf:""}
  constructor(private authService: AuthService,private errorService:ErrorService, private sanitizer:DomSanitizer, private modalService: BsModalService, private router: Router, private _formBuilder: FormBuilder, private alertService: AlertService, private navegarService: NavegacionService) {
   try{
     
    if (this.router.getCurrentNavigation().extras.state === undefined || this.router.getCurrentNavigation().extras.state === null) {
      this.navegarService.navegarRegistro()
    } else {
      this.data = this.router.getCurrentNavigation().extras.state
    }
    this.obtenerDocumentos()

    if(this.data.prm_area===null ){this.data.prm_area={

      area:""
    }}
    if(this.data.prm_depedencia===null ){this.data.prm_depedencia
      ={
        dependencia:""
      }
    }
    if(this.data.prm_rol===null ){
      this.data.prm_rol={
      rol:""
    }}

    this.form = this._formBuilder.group({
      dni: [this.data.dni, []],
      nombre: [this.data.nombre, []],
      apellido: [this.data.apellido, []],
      correo: [this.data.correo.trim(), [Validators.required, Validators.email]],
      dependencia: [this.data.prm_depedencia.dependencia, []],
      area: [this.data.prm_area.area, []],
      rol: [this.data.prm_rol.rol, []],
      clave: ["", [Validators.required]],
      confirm_clave: ["", [Validators.required]],
      humano: [false, [Validators.required, Validators.requiredTrue]],
      tratamiento: [false, [Validators.required, Validators.requiredTrue]],
      terminos: [false, [Validators.required, Validators.requiredTrue]],

    },
      {
        validator: [this.MustMatch('clave', 'confirm_clave')]
      });

    }catch(e){
      this.navegarService.navegarLogin()
    }
  }

  ngOnInit() {
  }


  openModal(template: TemplateRef<any>, c) {
    this.modalRef = this.modalService.show(
      template, {
      class: c, backdrop: true,
      ignoreBackdropClick: true
    }
    );
  }
  closeModal() {
    this.modalRef.hide();
  }
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  registrarDatos() {
    this.authService.crearCuentaUsuario(this.form.controls.dni.value, this.form.controls.clave.value, this.form.controls.correo.value).subscribe(resp => {
      if (resp.success) {

        this.alertService.crearMensaje("success", resp.message)
        this.gestionarOtp()
      } else {
        this.alertService.crearMensaje("error", resp.message)

      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  navegarRegistro() {
    this.navegarService.navegarRegistro()
  }
  gestionarOtp() {
    this.crearFormulario()
  }
  crearFormulario() {

    this.formOtp = this._formBuilder.group({
      otp: ["", [Validators.required]],
    }
    );
    this.openModal(this.modalOtp, 'modal-lg')
  }

  validarCodigo() {
    this.authService.validarOtp(this.form.controls.dni.value, this.formOtp.controls.otp.value).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.closeModal()
        this.authService.almacenarToken(resp.data)
        localStorage.setItem("obtenerInfo","true")
        localStorage.setItem(Properties.VAR_LOGIN, "true")

        this.navegarService.navegarInicio()
      } else {
        this.alertService.crearMensaje("error", resp.message)
        this.formOtp.controls.otp.setValue("")
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }


  reenviarCodigo(){
    this.authService.reenviarOtp(this.form.controls.correo.value).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
     
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }


  obtenerDocumentos() {
    this.authService.obtenerDocumentos().subscribe(resp => {
      if (resp.success) {
        this.documentos = resp.data
      } else {

      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  aceptar(doc){
    if(doc==="TERMINOS Y CONDICIONES"){
      this.form.controls.terminos.setValue(true)
    }
    if(doc==="TRATAMIENTO DE DATOS"){
      this.form.controls.tratamiento.setValue(true)
    }
  }

  seleccionarDocumento(titulo, pdf,modalPdf) {
    this.documentoSeleccionado.titulo=titulo
    this.documentoSeleccionado.pdf=pdf

    this.openModal(modalPdf,"modal-lg")
  }

  photoURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.documentoSeleccionado.pdf);
  }

  imprimir(){
  }
}
