import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Properties } from '../../properties';
import { AlertService } from '../../services/alert/alert.service';
import { AuthService } from '../../services/auth/auth.service';
import { ErrorService } from '../../services/error/error.service';
import { EventosService } from '../../services/eventos/eventos.service';
import { NavegacionService } from '../../services/navegacion/navegacion.service';
import { PerfilService } from '../../services/perfil/perfil.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
  @ViewChild('nav', { static: false }) ds: DragScrollComponent;
  eventos = []
  eventosMes = []
  eventosMesAux = []
  vermenos = false
  eventoSeleccionado
  eventosPropios = []
  modalRef?: BsModalRef;


  loginForm: FormGroup
  eventosEnEjecucion = []
  eventosFinalizados = []
  eventosPublicados = []
  constructor(private navegacionService: NavegacionService, private errorService: ErrorService, private ngxUiLoaderService: NgxUiLoaderService, private _formBuilder: FormBuilder, private loginService: AuthService, private modalService: BsModalService, private perfilService: PerfilService, private eventoService: EventosService, private alertService: AlertService) {
    localStorage.setItem("obtenerInfo", "true")

    this.gotoTop()
    this.obtenerEventos()

  }

  obtenerMiseventos() {
    this.ngxUiLoaderService.start()

    this.perfilService.obtenerEventos().subscribe(resp => {
      if (resp.success) {
        this.eventosPropios = resp.data
      } else {

      }
      this.ngxUiLoaderService.stop()

    }, error => {
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }


  obtenerEventoRegistrado(evento) {
    ////console.log(this.eventosMes,evento)
    if (this.eventosPropios.find(x => x.id === evento.id) != undefined) {
      return true
    } else {
      return false

    }
  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    // Starting ngx-drag-scroll from specified index(3)
    setTimeout(() => {
      //this.ds.moveTo(3);
    }, 0);
  }
  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  moveTo(index) {
    this.ds.moveTo(index);
  }

  verDetalle(evento) {
    this.obtenerDetalleEvento(evento)
  }

  obtenerEventos() {
    this.ngxUiLoaderService.start()

    this.eventoService.obtenerEventosDisponibles().subscribe(resp => {

      if (resp.success) {
        this.eventos = resp.data
        this.vermenos = false
        this.eventosFinalizados=[]
        
        this.eventosEnEjecucion =[] 
        this.eventosPublicados =[]

        resp.data.en_ejecucion.forEach(element => {
          element.rango_fecha=this.obtenerStringEvento(element)
          this.eventosEnEjecucion.push(element)
        });

        resp.data.finalizados.forEach(element => {
          element.rango_fecha=this.obtenerStringEvento(element)
          this.eventosFinalizados.push(element)
        });

        resp.data.publicados.forEach(element => {
          element.rango_fecha=this.obtenerStringEvento(element)
          this.eventosPublicados.push(element)
        });
      

        

      } else {

        this.alertService.crearMensaje("errror", resp.message)
      }
      this.obtenerMiseventos()

      this.ngxUiLoaderService.stop()

    }, error => {
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  obtenerStringEvento(evento) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    let fecha1 = new Date(evento.fecha_hora_inicio)
    let fecha2 = new Date(evento.fecha_hora_fin)

    let hora1: any = fecha1.getHours()
    let minuto1: any = fecha1.getMinutes()

    let hora2: any = fecha2.getHours()
    let minuto2: any = fecha2.getMinutes()
    if (hora1 < 10) {
      hora1 = "0" + hora1
    }
    if (minuto1 < 10) {
      minuto1 = "0" + minuto1
    }
    if (hora2 < 10) {
      hora2 = "0" + hora2
    }
    if (minuto2 < 10) {
      minuto2 = "0" + minuto2
    }

    return ("DEL " + fecha1.toLocaleDateString("es-ES", options) + " - " + hora1 + ":" + minuto1 + " AL " + fecha2.toLocaleDateString("es-ES", options) + " - " + hora2 + ":" + minuto2).toUpperCase()

  }

  verTodo() {
    this.eventosMes = this.eventos
    this.vermenos = true
  }

  verMenos() {
    this.eventosMes = this.eventosMesAux
    this.vermenos = false
  }


  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  obtenerDetalleEvento(evento) {

    this.navegacionService.navegarDetalleEvento(evento)

  }

  seleccionarEvento(evento) {
    this.eventoSeleccionado = evento
  }

  registrarEvento() {
    this.eventoService.vincularEvento(this.eventoSeleccionado.id).subscribe(resp => {
      if (resp.success) {

        this.navegacionService.navegarMisEventos()
      } else {
        this.alertService.crearMensaje("error", resp.message)

      }
    }, error => {
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  obtenerLocalStorage() {

    if (localStorage.getItem(Properties.VAR_LOGIN) === "true") {
      return true
    } else {
      return false

    }
  }

  navegarLogin() {
    this.navegacionService.navegarLogin()
  }

  openModal(template: TemplateRef<any>,) {
    this.modalRef = this.modalService.show(
      template, {
      class: 'modal-sm', backdrop: true,
      ignoreBackdropClick: true
    }
    );
  }
  closeModal() {
    this.modalRef.hide();
  }


  generarModal(modal) {
    this.crearFormulario()
    this.openModal(modal)
  }


  login() {
    this.loginService.autenticacion(this.loginForm.controls.email.value, this.loginForm.controls.clave.value).subscribe(resp => {
      if (resp.success) {
        localStorage.setItem(Properties.VAR_LOGIN, "true")
        this.alertService.crearMensaje('success', resp.message)
        localStorage.setItem("obtenerInfo", "true")

        this.loginService.almacenarToken(resp.data)
        this.closeModal()
      } else {
        this.loginForm.controls.clave.setValue("")
        this.alertService.crearMensaje("error", resp.message)
      }
    }, error => {
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  crearFormulario() {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      clave: ['', [Validators.required]],
    }
    );
  }

  navegarRegistro() {
    this.closeModal()
    this.navegacionService.navegarRegistro()
  }

  navegarOlvideClave() {
    this.closeModal()
    this.navegacionService.navegarOlvideClave()
  }

  desvincularEvento() {

    this.eventoService.desvincularEvento(this.eventoSeleccionado.id).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.obtenerEventos()
        this.obtenerMiseventos()

      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    }, error => {
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })

  }

  removetitulo(descripcion) {
    let aux = descripcion.replaceAll('<h1>', '<h1 style="font-size:10px">');
    aux = descripcion.replaceAll('<h2>', '<p>');
    aux = descripcion.replaceAll('</h1>', '</p>');
    aux = descripcion.replaceAll('</h2>', '</p>');
    aux = descripcion.replaceAll('<h3>', '<p>');
    aux = descripcion.replaceAll('</h3>', '</p>');
    aux = descripcion.replaceAll('<h4>', '<p>');
    aux = descripcion.replaceAll('</h4>', '</p>');
    aux = descripcion.replaceAll('<h5>', '<p>');
    aux = descripcion.replaceAll('</h5>', '<p>');
  
    return aux
  }
}


