import { Component, OnInit, TemplateRef } from '@angular/core';
import { EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { NavegacionService } from '../../services/navegacion/navegacion.service';
import { EventosService } from '../../services/eventos/eventos.service';
import { AlertService } from '../../services/alert/alert.service';
import { PerfilService } from '../../services/perfil/perfil.service';
import { Properties } from '../../properties';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import esLocale from '@fullcalendar/core/locales/es';
import { ErrorService } from '../../services/error/error.service';

@Component({
  selector: 'app-detalle-evento',
  templateUrl: './detalle-evento.component.html',
  styleUrls: ['./detalle-evento.component.scss']
})
export class DetalleEventoComponent implements OnInit {
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarEvents: EventInput[] = [  ];
  ca
  visible = false
  modalRef?: BsModalRef;
  evento
  eventosPropios = []
  login = false
  public calendarOptions: any;
  loginForm: FormGroup
  id
  options = { locales: [esLocale], }

  constructor(private modalService: BsModalService,private errorService:ErrorService, private ngxUiLoaderService: NgxUiLoaderService,private route: ActivatedRoute,private loginService:AuthService,private _formBuilder: FormBuilder,private navegacionService:NavegacionService, private perfilService: PerfilService, private alertService: AlertService, private eventoService: EventosService, private router: Router, private navegarService: NavegacionService) {
    localStorage.setItem("obtenerInfo","true")

    this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.gotoTop()
      this.obtenerDetalleEvento()
      this.obtenerMiseventos()

   });
   
    console.log(this.evento)
  }

  ngOnInit() {

   
  }


  obtenerMiseventos() {
    this.perfilService.obtenerEventos().subscribe(resp => {
      if (resp.success) {
        this.eventosPropios = resp.data
      } else {

      }
    })
  }
  navegarInicio(){
    this.navegacionService.navegarInicio()
  }

  obtenerEventoRegistrado() {

      if (this.eventosPropios.find(x => Number(x.id) === Number(this.id)) != undefined) {
        return true
      } else {
        return false
      }

  
  }

  handleDateClick(calDate) {
    // console.log(calDate);
  }

  eventClicked(calDate) {
    // console.log(calDate);
  }




  openModal(template: TemplateRef<any>, c) {
    this.modalRef = this.modalService.show(
      template, {
      class: c,
      backdrop: true,
      ignoreBackdropClick: true
    }
    );
  }
  closeModal() {
    this.modalRef.hide();
  }
atras(){
  window.history.back();
}

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  obtenerStringEventoFecha() {
    if(this.evento!=undefined){
      
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    let fecha1 = new Date(this.evento.fecha_hora_inicio)

    let minute: any = fecha1.getMinutes()
    let hour: any = fecha1.getHours()

    if (Number(minute) < 10) {
      minute = "0" + minute
    }
    if (Number(hour) < 10) {
      hour = "0" + hour
    }


    return fecha1.toLocaleDateString("es-ES", options) + " " + hour + ":" + minute

    }
    return ""
  }

  desvincularEvento() {
      
      this.eventoService.desvincularEvento(this.id).subscribe(resp => {
        if (resp.success) {
          this.alertService.crearMensaje("success", resp.message)
          this.obtenerDetalleEvento()
          this.obtenerMiseventos()

        } else {
          this.alertService.crearMensaje("error", resp.message)
        }
      },error=>{
        this.errorService.notificarError(error.message).subscribe(resp=>{})
      })
   
  }


  obtenerDetalleEvento() {
    this.ngxUiLoaderService.start()

    this.eventoService.obtenerEvento(this.id).subscribe(resp => {
      if (resp.success) {
        console.log(resp.data)

        this.evento = resp.data[0]
        this.calendarEvents.push(
          {title: this.evento.nombre, start: new Date(this.evento.fecha_hora_inicio),end:new Date(this.evento.fecha_hora_fin) })
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
      this.ngxUiLoaderService.stop()

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }
  registrarEvento() {
   
   this.ngxUiLoaderService.start()
    this.eventoService.vincularEvento(this.id).subscribe(resp => {
      if (resp.success) {
       
          console.log(this.calendarEvents)
        
        this.obtenerDetalleEvento()
        this.obtenerMiseventos()
        this.alertService.crearMensaje("success", resp.message)

      } else {
        this.alertService.crearMensaje("error", resp.message)

      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }



  obtenerLocalStorage(){

    if( localStorage.getItem(Properties.VAR_LOGIN)==="true"){
     return true
    }else{
     return false
   
    }
   }

   navegarLogin(){
     this.navegacionService.navegarLogin()
   }

   



  generarModal(modal){
    this.crearFormulario()
    this.openModal(modal,'modal-sm')
  }
  abrirUrl(url) {
    window.open(url, "_blank")
  }
  
  loginUser() {
    this.loginService.autenticacion(this.loginForm.controls.email.value, this.loginForm.controls.clave.value).subscribe(resp => {
      if (resp.success) {
        localStorage.setItem("obtenerInfo","true")

        localStorage.setItem(Properties.VAR_LOGIN,"true")
        this.alertService.crearMensaje('success', resp.message)
        this.loginService.almacenarToken(resp.data)
        this.closeModal()
      } else {
        this.loginForm.controls.clave.setValue("")
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  crearFormulario(){
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      clave: ['', [Validators.required]],
    }
    );
  }

  
  navegarRegistro(){
    this.closeModal()
    this.navegacionService.navegarRegistro()
  }

  navegarOlvideClave(){
    this.closeModal()
    this.navegacionService.navegarOlvideClave()
  }

   
  loginws() {
    this.loginService.autenticacion(this.loginForm.controls.email.value, this.loginForm.controls.clave.value).subscribe(resp => {
      if (resp.success) {
        localStorage.setItem(Properties.VAR_LOGIN,"true")
        this.alertService.crearMensaje('success', resp.message)
        this.loginService.almacenarToken(resp.data)
        this.closeModal()
      } else {
        this.loginForm.controls.clave.setValue("")
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

}
