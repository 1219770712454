import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Properties } from '../../properties';

@Injectable({
  providedIn: 'root'
})
export class EventosService {

  constructor(private http: HttpClient, private router: Router) { }

  obtenerEventosDisponibles(): Observable<any> {

    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
       // "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
      let raw={
        
      
      }
   
    return this.http.post(Properties.URL + 'api-portal-eventos/eventos/obtener-eventos-disponibles',raw, { headers: headers })


  }

  obtenerEvento(id): Observable<any> {

    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        //"AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
      let raw={
        "id_evento"         : id

      
      }
   
    return this.http.post(Properties.URL + 'api-portal-eventos/eventos/obtener-detalles-eventos',raw, { headers: headers })


  }
  vincularEvento(id): Observable<any> {

    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
      let raw={
        "id_evento":id

      
      }
   
    return this.http.post(Properties.URL + 'api-portal-eventos/asistencia-eventos/vincular-evento',raw, { headers: headers })


  }

  desvincularEvento(id): Observable<any> {

    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
      let raw={
        "id_evento":id

      
      }
   
    return this.http.post(Properties.URL + 'api-portal-eventos/asistencia-eventos/desvincular-evento',raw, { headers: headers })


  }
}
