import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthService } from '../../../services/auth/auth.service';
import { ErrorService } from '../../../services/error/error.service';
import { NavegacionService } from '../../../services/navegacion/navegacion.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {
  form: FormGroup

  constructor(private location:Location,private authService: AuthService,private errorService:ErrorService,private _formBuilder: FormBuilder,private alertService:AlertService,private navegarService:NavegacionService) {
    this.form = this._formBuilder.group({
      dni: ['', [Validators.required]],
    }
    );

  }

  ngOnInit() {
  }


  buscarDni() {
    this.authService.buscarDni(this.form.controls.dni.value).subscribe(resp=>{
      if(resp.success){
        this.alertService.crearMensaje("success",resp.message)
        resp.data.dni=this.form.controls.dni.value
        this.navegarService.navegarCrearCuenta(resp.data)
      }else{
        this.alertService.crearMensaje("error",resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  volver(){
this.location.back()
  }
}
