import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavegacionService {

  constructor(private router:Router) { }

  navegarInicio(){
    this.router.navigateByUrl("landing")
  }
  navegarCalendario(){
    this.router.navigateByUrl("landing/calendario")
  }
  navegarDetalleEvento(evento){
    this.router.navigateByUrl("landing/ver-detalle/"+evento.id)
  }
  navegarOpcionesEventos(registro){
    this.router.navigateByUrl("landing/eventos-opciones",{ state: registro })

  }
  navegarRegistro(){
    this.router.navigateByUrl("registro")

  }
  navegarCrearCuenta(data){
    this.router.navigateByUrl("registro/datos",{state:data})

  }
  navegarLogin(){
    this.router.navigateByUrl("login")

  }
  navegarOlvideClave(){
    this.router.navigateByUrl("olvide-clave")

  }

  navegarPerfil(){
    this.router.navigateByUrl("landing/perfil")

  }

  navegarMisEventos(){
    this.router.navigateByUrl("landing/mis-eventos")
  }
}
