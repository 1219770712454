// Angular Core
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  BrowserModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { LOCALE_ID, APP_INITIALIZER, NgModule } from '@angular/core';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs, 'es');
import { DragScrollModule } from 'ngx-drag-scroll';
import { CookieService } from 'ngx-cookie-service';

import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
  DatePipe,
} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { NgxResponsiveStackTableModule } from 'ngx-responsive-stack-table';
import { FlexLayoutModule } from '@angular/flex-layout';

// Routing
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

// Layouts
import { Landing } from './@pages/layouts';
// Layout Service - Required

// Shared Layout Components
import { SharedModule } from './@pages/components/shared.module';

import { NgOtpInputModule } from 'ng-otp-input';
import { ImageUploadModule } from 'angular2-image-upload';

// Basic Bootstrap Modules
import {
  BsDropdownModule,
  AccordionModule,
  AlertModule,
  ButtonsModule,
  CollapseModule,
  ModalModule,
  ProgressbarModule,
  TabsModule,
  TooltipModule,
  TypeaheadModule,
} from 'ngx-bootstrap';

// Thirdparty Components / Plugins - Optional
import { NvD3Module } from 'ngx-nvd3';
import { NgxEchartsModule } from 'ngx-echarts';
import { IsotopeModule } from 'ngx-isotope';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { QuillModule } from 'ngx-quill';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CurrencyMaskDirective } from './directive/currency-mask.directive';
import { NumbersOnlyDirective } from './directive/numbers-only.directive';

// Service - Demo content - Optional

// Social Page - Optional

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

// Dashboard Widgets - Optional
import { DashboardModule } from './components/dashboard/dashboard.module';

// Dashboards - Optional
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { NgxCurrencyModule } from 'ngx-currency';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CopyPasteDisabledDirective } from './directive/copy-paste-disabled.directive';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';

import {
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatButtonModule,
  MatFormFieldModule,
  MatRippleModule,
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  MatStepperModule,
  MatCheckboxModule,
  MatTabsModule,
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

import { NgApexchartsModule } from 'ng-apexcharts';

import { MatGridListModule } from '@angular/material/grid-list';
import { LayoutModule } from '@angular/cdk/layout';

import { FileUploadModule } from 'ng2-file-upload';
import { WebcamModule } from 'ngx-webcam';

import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';


import { NgxUploaderModule } from 'ngx-uploader';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { NgxAnimationsModule } from 'ngx-animations';
import { LettersOnlyDirective } from './directive/letters-only.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InicioComponent } from './components/inicio/inicio.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegistroComponent } from './components/auth/registro/registro.component';
import { RegistroDatosComponent } from './components/auth/registro-datos/registro-datos.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CalendarioComponent } from './components/calendario/calendario.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DetalleEventoComponent } from './components/detalle-evento/detalle-evento.component'; // must go before plugins
import { OlvideClaveComponent } from './components/auth/olvide-clave/olvide-clave.component';
import { PerfilComponent } from './components/perfil/perfil.component';

import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { AlfaNumericOnlyDirective } from './directive/alfanumeric.directive';
import { MisEventosComponent } from './components/mis-eventos/mis-eventos.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PromptComponent } from './@pages/components/prompt/prompt.component';
import { PwaService } from './services/pwa/pwa.service';
import { DashboardComponent } from './components/dashboard/frutafresca/dashboard.component';

const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};



const ngxUiLoaderConfig : NgxUiLoaderConfig=
{
  "bgsColor": "#70c9c0",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "delay": 0,
  "fgsColor": "rgb(247,194,60)",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "ball-scale-multiple",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 200,
  "logoUrl": "assets/img/colmayor-1.svg",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgb(0,0,0)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
}




@NgModule({
  declarations: [
    AppComponent,
    Landing,
    InicioComponent,  
    UsuariosComponent,
    LoginComponent,
    RegistroComponent,
    RegistroDatosComponent,
     CalendarioComponent,
     DetalleEventoComponent,
     NumbersOnlyDirective,
     OlvideClaveComponent,
     PerfilComponent,
     AlfaNumericOnlyDirective,
     MisEventosComponent,
     PromptComponent,
     CurrencyMaskDirective,
     CopyPasteDisabledDirective,
     LettersOnlyDirective,
     DashboardComponent
  ],

  imports: [
    FileUploadModule,
    FullCalendarModule,
    AngularFileUploaderModule,
    NgxUploaderModule,
    NgxPaginationModule,
    NgxResponsiveStackTableModule,
    NgApexchartsModule,
    MatTableModule,
    MatSelectModule,
    MatPaginatorModule,
    MatMenuModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatCardModule,
    MatSortModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatListModule,
    MatBottomSheetModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatIconModule,
    MatTooltipModule,
    MatTabsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    BrowserModule,
    WebcamModule,
    ImageUploadModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    PdfViewerModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    SharedModule,
    NgOtpInputModule,
    SweetAlert2Module.forRoot(),
    SweetAlert2Module,
    RouterModule.forRoot(AppRoutes),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    DragScrollModule,
    ProgressbarModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    ImageUploadModule.forRoot(),
    FileUploadModule,
    ToastrModule.forRoot({ timeOut: 10000 }),
    NvD3Module,
    NgxEchartsModule,
    IsotopeModule,
    NgxDnDModule,
    QuillModule.forRoot(),
    PerfectScrollbarModule,
    DashboardModule,
    MatCheckboxModule,

    NgxCurrencyModule,
    CarouselModule.forRoot(),
    ReactiveFormsModule,
    MatGridListModule,
    NgxAnimationsModule,
    LayoutModule,
    PdfJsViewerModule,
    BsDatepickerModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],

  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    DecimalPipe,

  
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    MatDatepickerModule,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },

    DatePipe,
    MatDatepickerModule,  
    CookieService,
    
    {provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true},
  ],  
  entryComponents: [PromptComponent],

  bootstrap: [AppComponent],
})
export class AppModule {}
