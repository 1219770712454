import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Properties } from '../../properties';
import { AlertService } from '../../services/alert/alert.service';
import { AuthService } from '../../services/auth/auth.service';
import { ErrorService } from '../../services/error/error.service';
import { EventosService } from '../../services/eventos/eventos.service';
import { NavegacionService } from '../../services/navegacion/navegacion.service';
import { PerfilService } from '../../services/perfil/perfil.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  @ViewChild("exampleModalotp", { static: false }) modalOtp

  dataUsuario
  formGestion
  notificaciones = []
  eventos = []
  eventosPropios = []
  lugar = "perfil"
  eventoSeleccionado
  formOtp: FormGroup
  modalRef?: BsModalRef;


  constructor(private authService: AuthService,private errorService:ErrorService, private modalService: BsModalService, private router: Router, private eventoService: EventosService, private navegacionService: NavegacionService, private alertService: AlertService, private perfilService: PerfilService, private _formBuilder: FormBuilder) {
    localStorage.setItem("obtenerInfo", "true")

    this.formGestion = this._formBuilder.group({
      nombre: ["", [Validators.required]],
      apellido: ["", [Validators.required]],
      dni: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      dependencia: ["",],
      area: ["",],
      cargo: ["", [Validators.required]],
    })


    if (this.router.getCurrentNavigation().extras.state != undefined && this.router.getCurrentNavigation().extras.state != null) {
      this.lugar = "notificaciones"
    }
    this.obtenerInformacionUsuario()
    this.obtenerNoticiaciones()
    this.obtenerEventos()

  }

  ngOnInit() {
  }
  obtenerInformacionUsuario() {
    this.authService.obtenerInformacionUsuario().subscribe(resp => {
      if (resp.success) {
        // this.alertService.crearMensaje('success',resp.message)
        this.dataUsuario = resp.data
        this.crearFormulario()
      } else {
        this.alertService.crearMensaje('error', resp.message)
        this.authService.cerrarSesion()
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  crearFormulario() {


    this.formGestion = this._formBuilder.group({
      nombre: [this.dataUsuario.primer_nombre],
      apellido: [this.dataUsuario.primer_apellido],
      dni: [this.dataUsuario.dni, [Validators.required]],
      email: [this.dataUsuario.email, [Validators.required, Validators.email]],
      dependencia: [""],
      area: [""],
      cargo: [""],
    })
  }


  obtenerNoticiaciones() {
    this.perfilService.obtenerNotificaciones().subscribe(resp => {
      if (resp.success) {
        this.notificaciones = resp.data
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }


  cerrarSesion() {
    this.authService.cerrarSesion()
    localStorage.setItem("obtenerInfo", "false")

  }

  obtenerEventos() {
    this.perfilService.obtenerEventos().subscribe(resp => {

      if (resp.success) {
        this.eventos = resp.data
        this.eventosPropios = resp.data

      } else {

      }

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  cambiarLugar(evt) {
    //console.log(evt)
    this.lugar = evt

    if (this.lugar === "eventos") {
      this.obtenerEventos()
    }
  }


  obtenerStringEvento(evento) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    let fecha1 = new Date(evento.fecha_hora_inicio)
    let fecha2 = new Date(evento.fecha_hora_fin)

    return ("DEL " + fecha1.toLocaleDateString("es-ES", options) + " AL " + fecha2.toLocaleDateString("es-ES", options)).toUpperCase()

  }


  buscarEvento(id) {
    //console.log(this.eventos, id)
    return this.eventos.find(x => x.id === id)
  }



  obtenerEventoRegistrado(evento) {
    ////console.log(this.eventosMes,evento)
    if (this.eventosPropios.find(x => x.id === evento.id) != undefined) {
      return true
    } else {
      return false

    }
  }
  verDetalle(evento) {
    this.obtenerDetalleEvento(evento)
  }
  obtenerDetalleEvento(evento) {

    this.navegacionService.navegarDetalleEvento(evento)

  }

  seleccionarEvento(evento) {
    this.eventoSeleccionado = evento
  }

  desvincularEvento() {

    this.eventoService.desvincularEvento(this.eventoSeleccionado.id).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.obtenerEventos()

      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })

  }
  actualizar() {
    this.perfilService.actualizarEmail(this.formGestion.controls.email.value).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.crearFormularioModal()

      } else {
        this.alertService.crearMensaje("error", resp.message)
        this.formOtp.controls.otp.setValue("")
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  closeModal() {
    this.modalRef.hide();
  }

  crearFormularioModal() {

    this.formOtp = this._formBuilder.group({
      otp: ["", [Validators.required]],
    }
    );
    this.openModal(this.modalOtp, 'modal-lg')
  }
  openModal(template: TemplateRef<any>, c) {
    this.modalRef = this.modalService.show(
      template, {
      class: c, backdrop: true,
      ignoreBackdropClick: true
    }
    );
  }

  validarCodigo() {
    this.perfilService.validarOtp(this.formOtp.controls.otp.value).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.closeModal()
        this.obtenerInformacionUsuario()
      } else {
        this.alertService.crearMensaje("error", resp.message)
        this.formOtp.controls.otp.setValue("")
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  reenviarCodigo(){
    this.perfilService.reenviarOtp(this.formGestion.controls.email.value).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }
}

