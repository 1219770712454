import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Properties } from '../../properties';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loginBool
  constructor(private http: HttpClient, private router: Router) { }
  almacenarToken(token) {
    localStorage.setItem(Properties.VAR_TOKEN_ADMIN, token)
  }
  obtenerToken() {
    try {
      return localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

    } catch {
      return null
    }
  }
  autenticacion(email, clave): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        //"AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
    let raw = {
      email: email,
      clave: clave
    }
    return this.http.post(Properties.URL + 'api-portal-eventos/usuarios/autenticacion', raw, { headers: headers })
  }
  recordarClave(email): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        //"AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
    let raw = {
      email: email,
    }
    return this.http.post(Properties.URL + 'api-portal-eventos/usuarios/recordar-clave', raw, { headers: headers })
  }
  cambiarClave(email, otp, clave): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        //"AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
    let raw = {
      "email": email,
      "otp": otp,
      "clave": clave
    }
    return this.http.post(Properties.URL + 'api-portal-eventos/usuarios/cambiar-clave', raw, { headers: headers })
  }
  cerrarSesion() {
    this.router.navigateByUrl("landing")
    localStorage.setItem("obtenerInfo","false")

    localStorage.removeItem(Properties.VAR_TOKEN_ADMIN)
    localStorage.removeItem(Properties.VAR_LOGIN)
  }

  buscarDni(dni): Observable<any> {

    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        //"AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

    let raw = {
      "dni": dni
    }
    return this.http.post(Properties.URL + 'api-portal-eventos/registro-usuarios/buscar-dni', raw, { headers: headers })


  }

  validarToken(): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
    return this.http.get(Properties.URL + 'api-portal-eventos/usuarios/validar-token', { headers: headers })

  }
  obtenerInformacionUsuario(): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
    return this.http.get(Properties.URL + 'api-portal-eventos/usuarios/obtener-datos-usuario', { headers: headers })

  }
  crearCuentaUsuario(dni, clave, email): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        //"AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

    let raw = {
      dni: dni,
      email: email,
      clave: clave
    }
    return this.http.post(Properties.URL + 'api-portal-eventos/registro-usuarios/crear-cuenta', raw, { headers: headers })

  }

  validarOtp(dni, otp): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        //"AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

    let raw = {
      "dni": dni,
      "otp": otp
    }

    return this.http.post(Properties.URL + 'api-portal-eventos/registro-usuarios/validar-cuenta', raw, { headers: headers })

  }

  reenviarOtp(email): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        //"AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });



    return this.http.post(Properties.URL + 'api-portal-eventos/registro-usuarios/reenviar-codigo-registro',{email:email}, { headers: headers })

  }


  obtenerDocumentos(): Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        //"AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

 

    return this.http.get(Properties.URL + 'api-portal-eventos/registro-usuarios/obtener-documentos-legales', { headers: headers })

  }


  obtenerBoolLog(){
    return this.loginBool
  }
  cambiarBoolLog(bol){
    return this.loginBool=bol
  }
}
