import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Properties } from '../../../properties';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthService } from '../../../services/auth/auth.service';
import { NavegacionService } from '../../../services/navegacion/navegacion.service';
import { PerfilService } from '../../../services/perfil/perfil.service';
import { PwaService } from '../../../services/pwa/pwa.service';

@Component({
  selector: 'landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Landing implements OnInit {
  dataUsuario
  title
  isScreenSmall: boolean;
  @HostListener('window:beforeinstallprompt', ['$event'])
  deferredPrompt: any;
  showButton = false;
  notificaciones = []

  properties
  deferredPrompt2
  puedeDescargar = false
  constructor(private router: Router, private perfilService: PerfilService, private alertService: AlertService, private navegacion: NavegacionService, private authService: AuthService, public pwaService: PwaService) {
    this.obtenerNoticiaciones()
    this.properties = Properties.urlAdmin
    this.validarToken()
    this.obtenerInformacionUsuario()

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt2 = e;
    });
    if (this.deferredPrompt2 != undefined) {
      this.puedeDescargar = true
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
      }

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        if (event.url === "/landing") {
          this.title = "INICIO"
        }
        if (event.url === "/landing/calendario") {
          this.title = "CALENDARIO"
        }
        if (event.url === "/landing/perfil") {
          this.title = "PERFIL"
        }
        if (event.url === "/landing/mis-eventos") {
          this.title = "MIS EVENTOS"
        }
        if (event.url === "/admin/ver-detalle-evento") {
          this.title = "EVENTOS / DETALLE EVENTO"
        }
        if (event.url === "/admin/parametros/roles") {
          this.title = "PARAMETROS / ROLES"
        }
        if (event.url === "/admin/parametros/dependencias") {
          this.title = "PARAMETROS / DEPENDENCIAS"
        }
        if (event.url === "/admin/parametros/areas") {
          this.title = "PARAMETROS / AREAS"
        }
        if (event.url === "/admin/parametros/facultad") {
          this.title = "PARAMETROS / FACULTAD"
        }
        if (event.url === "/admin/parametros/programa") {
          this.title = "PARAMETROS / PROGRAMA"
        }
        if (event.url === "/admin/parametros/estado-participantes") {
          this.title = "PARAMETROS / ESTADO DE PARTICIPANTES"
        }
        if (event.url === "/admin/parametros/estado-evento") {
          this.title = "PARAMETROS / ESTADO DE EVENTO"
        }
        if (event.url === "/admin/usuarios") {
          this.title = "USUARIOS"
        }
        if (event.url === "/admin/log") {
          this.title = "LOGS"
        }
      }

      if (event instanceof NavigationError) {
        // Hide progress spinner or progress bar

        // Present error to user
        //console.log(event.error);
      }
    });
  }
  ngOnInit() {

  }

  navegarInicio() {
    this.navegacion.navegarInicio()
  }
  navegarCalendario() {
    this.navegacion.navegarCalendario()
  }

  navegarPerfil() {
    this.navegacion.navegarPerfil()
  }

  cerrarSesion() {
    this.authService.cerrarSesion()
  }

  validarToken() {
    this.authService.validarToken().subscribe(resp => {
      if (resp.success) {
        // this.alertService.crearMensaje('success',resp.message)
      } else {
        this.alertService.crearMensaje('error', resp.message)
        this.authService.cerrarSesion()
      }
    })
  }
  obtenerInformacionUsuario() {
    if (localStorage.getItem(Properties.VAR_LOGIN) === "true" && localStorage.getItem("obtenerInfo") === "true") {
      localStorage.setItem("obtenerInfo", "false")
      try {
        this.obtenerNoticiaciones()
        this.authService.obtenerInformacionUsuario().subscribe(resp => {
          if (resp.success) {
            this.authService.cambiarBoolLog(true)
            localStorage.setItem("obtenerInfo", "false")
            this.dataUsuario = resp.data
          } else {
            this.alertService.crearMensaje('error', resp.message)
            this.authService.cerrarSesion()
          }


        })
      } catch (e) {

      }
    }

  }




  irALogin() {
    this.navegacion.navegarLogin()
  }

  irAMisEventos() {
    this.navegacion.navegarMisEventos()
  }

  irAPerfil(evt) {
    this.router.navigateByUrl("landing/perfil", { state: evt })
  }

  obtenerLocalStorage() {

    if (localStorage.getItem(Properties.VAR_LOGIN) === "true") {
      if (localStorage.getItem("obtenerInfo") === "true") {
        this.obtenerInformacionUsuario()
      }
      return true
    } else {
      return false

    }
  }

  login() {
    this.navegacion.navegarLogin()
  }


  instalarPWA() {

    this.pwaService.initPwaPrompt()
  }


  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }
  addToHomeScreen() {

    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //console.log('User accepted the A2HS prompt');
        } else {
          //console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }

  obtenerNoticiaciones() {
    this.perfilService.obtenerNotificaciones().subscribe(resp => {
      if (resp.success) {
        this.notificaciones = resp.data
      } else {
        // this.alertService.crearMensaje("error", resp.message)
      }
    })
  }
}