import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Properties } from '../../properties';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  constructor(private http: HttpClient, private router: Router) { }



  obtenerNotificaciones(): Observable<any> {

    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

    return this.http.get(Properties.URL + 'api-portal-eventos/notificaciones/listar-mis-notificaciones', { headers: headers })
  }

  
  obtenerEventos(): Observable<any> {

    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
      

    return this.http.get(Properties.URL + 'api-portal-eventos/asistencia-eventos/listar-mis-eventos', { headers: headers })
  }

  actualizarEmail(email): Observable<any> {

    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
      let raw = {
        email: email,
      }

    return this.http.post(Properties.URL + 'api-portal-eventos/actualizar-email/cambiar-correo',raw, { headers: headers })
 
    
  }

  validarOtp( otp): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

    let raw = {
      "otp": otp
    }

    return this.http.post(Properties.URL + 'api-portal-eventos/actualizar-email/validar-cuenta ', raw, { headers: headers })

  }

  reenviarOtp(email): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

   

    return this.http.post(Properties.URL + 'api-portal-eventos/actualizar-email/reenviar-codigo',{email:email}, { headers: headers })
 
  }

}
