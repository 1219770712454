import { Component } from '@angular/core';
import { Properties } from './properties';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
declare let gtag: Function;
declare let fbq: Function;

import {
  SwPush,
  SwUpdate,
  UpdateAvailableEvent,
} from '@angular/service-worker';
import { EventInput } from '@fullcalendar/core';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app works!';
  message;
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarEvents: EventInput[] = [
    { title: 'Event Now', start: new Date() }
  ];

  constructor(

  ) {

  }
  ngOnInit() {

    if ('serviceWorker' in navigator) {
      // register the service worker
      navigator.serviceWorker.register('ngsw-worker.js')
        .then(function (reg) {
          //console.log("service worker registered");
        }).catch(function (err) {
          //console.log("error: ", err)
        });
    }
    try {
      setTimeout(r => {
        let deferredPrompt;
        window.addEventListener('beforeinstallprompt', (e) => {
          // Prevent Chrome 67 and earlier from automatically showing the prompt
          // e.preventDefault();
          // Stash the event so it can be triggered later.
          //console.log(e)
          deferredPrompt = e;
        });
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice
          .then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              //console.log('User accepted the A2HS prompt');
            } else {
              //console.log('User dismissed the A2HS prompt');
            }
            deferredPrompt = null;
          });


      }, 500
      )
    } catch (e) {

    }

  }


}
