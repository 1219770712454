import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert/alert.service';
import { ErrorService } from '../../services/error/error.service';
import { EventosService } from '../../services/eventos/eventos.service';
import { NavegacionService } from '../../services/navegacion/navegacion.service';
import { PerfilService } from '../../services/perfil/perfil.service';

@Component({
  selector: 'app-mis-eventos',
  templateUrl: './mis-eventos.component.html',
  styleUrls: ['./mis-eventos.component.scss']
})
export class MisEventosComponent implements OnInit {
  eventos = []
  constructor( private perfilService: PerfilService,private errorService:ErrorService,private alertService:AlertService,private eventoService:EventosService,private navegacionService:NavegacionService) { 
    localStorage.setItem("obtenerInfo","true")

    this.obtenerEventos()

  }

  ngOnInit() {
  }

  obtenerEventos() {
    this.perfilService.obtenerEventos().subscribe(resp => {

      if (resp.success) {
        this.eventos = resp.data

      } else {

      }

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  obtenerStringEvento(evento) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    let fecha1 = new Date(evento.fecha_hora_inicio)
    let fecha2 = new Date(evento.fecha_hora_fin)

    let hora1:any=fecha1.getHours()
    let minuto1:any=fecha1.getMinutes()
    
    let hora2:any=fecha2.getHours()
    let minuto2:any=fecha2.getMinutes()
    if(hora1<10){
      hora1="0"+hora1
    }
    if(minuto1<10){
      minuto1="0"+minuto1
    }
    if(hora2<10){
      hora2="0"+hora2
    }
    if(minuto2<10){
      minuto2="0"+minuto2
    }

    return ("DEL " + fecha1.toLocaleDateString("es-ES", options)+" - "+ hora1+":"+minuto1+ " AL " + fecha2.toLocaleDateString("es-ES", options)+" - "+ hora2+":"+minuto2 ).toUpperCase()

  }

  verDetalle(evento) {
    this.obtenerDetalleEvento(evento)
  }

  obtenerDetalleEvento(evento){
   
    this.navegacionService.navegarDetalleEvento(evento)

}
  

}
