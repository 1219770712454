import { Routes } from '@angular/router';

// Layouts
import { InicioComponent } from './components/inicio/inicio.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegistroComponent } from './components/auth/registro/registro.component';
import { RegistroDatosComponent } from './components/auth/registro-datos/registro-datos.component';
import { CalendarioComponent } from './components/calendario/calendario.component';
import { DetalleEventoComponent } from './components/detalle-evento/detalle-evento.component';
import { AuthGuard } from './guards/auth.guard';
import { OlvideClaveComponent } from './components/auth/olvide-clave/olvide-clave.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { MisEventosComponent } from './components/mis-eventos/mis-eventos.component';
import { Landing } from './@pages/layouts/landing/landing.component';


export const AppRoutes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "olvide-clave", component: OlvideClaveComponent },

  {
    path: "registro", children:
      [{ path: "cuenta", component: RegistroComponent },
      { path: "datos", component: RegistroDatosComponent },
      
      { path: '**', redirectTo: 'cuenta' },

 ]
  },  {
    path: "registro", children:
      [{ path: "cuenta", component: RegistroComponent },
      { path: "datos", component: RegistroDatosComponent },
      { path: '**', redirectTo: 'cuenta' }
 ]
  },


  {
    path: 'landing',
    component: Landing,

    children: [
      { path: "", component: InicioComponent },
      { path: "calendario", component: CalendarioComponent },
      { path: "ver-detalle/:id", component: DetalleEventoComponent },
      { 
        path: "perfil", component: PerfilComponent,
      canActivateChild: [AuthGuard],
    },
    { 
      path: "mis-eventos", component: MisEventosComponent,
    canActivateChild: [AuthGuard],
  }


     

    ],
  },
  { path: '**', redirectTo: 'landing' },

  // Executive
];
