import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Properties } from '../../../properties';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthService } from '../../../services/auth/auth.service';
import { ErrorService } from '../../../services/error/error.service';
import { NavegacionService } from '../../../services/navegacion/navegacion.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  modalRef?: BsModalRef;

  loginForm: FormGroup
  VERSION=Properties.VERSION
  constructor(private navegacion: NavegacionService,private errorService:ErrorService,private modalService: BsModalService, private _formBuilder: FormBuilder, private loginService: AuthService, private alertService: AlertService, private _cookieService: CookieService,) {
    localStorage.setItem("obtenerInfo","true")

    if(localStorage.getItem(Properties.VAR_TOKEN_ADMIN)!=null && localStorage.getItem(Properties.VAR_TOKEN_ADMIN)!=undefined){
     this.loginService.validarToken().subscribe(resp=>{
       if(resp.success){
        this.navegacion.navegarInicio()
       }else{

       }
     })
   }

    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      clave: ['', [Validators.required]],
      rememberMe: ['']
    }
    );
  }

  ngOnInit() {
    if (localStorage.getItem(Properties.RECORDARLOGIN) === "true") {
      this.loginForm.controls.email.setValue(this._cookieService.get(Properties.VAR_COCKIES_EMAIL));
      this.loginForm.controls.rememberMe.setValue(true);
    }
  }

  login() {
    this.loginService.autenticacion(this.loginForm.controls.email.value, this.loginForm.controls.clave.value).subscribe(resp => {
      if (resp.success) {
        localStorage.setItem(Properties.VAR_LOGIN,"true")
        this.alertService.crearMensaje('success', resp.message)
        this.navegacion.navegarInicio()
        this.loginService.almacenarToken(resp.data)
        localStorage.setItem("obtenerInfo","true")
        if (this.loginForm.value.rememberMe === true) {
          localStorage.setItem(Properties.RECORDARLOGIN, "true")
          this._cookieService.set(Properties.VAR_COCKIES_EMAIL, this.loginForm.controls.email.value);
        } else {
          localStorage.setItem(Properties.RECORDARLOGIN, "false")

        }
      } else {
        this.loginForm.controls.clave.setValue("")
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }
  navegarRegistro() {
    this.navegacion.navegarRegistro()
  }

  navegarOlvideClave(){
    this.navegacion.navegarOlvideClave()
  }


  openModal(template: TemplateRef<any>, c) {
    this.modalRef = this.modalService.show(
      template, {
      class: c, backdrop: true,
      ignoreBackdropClick: true
    }
    );
  }
  closeModal() {
    this.modalRef.hide();
  }

}
