import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';

import { TypeaheadModule } from 'ngx-bootstrap';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { RouterModule } from '@angular/router';

// import { SidebarComponent } from './components/sidebar/sidebar.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatDividerModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  MatListModule,
} from '@angular/material';
import { WebcamModule } from 'ngx-webcam';
import { NgxAnimationsModule } from 'ngx-animations';
import { FileUploadModule } from 'ng2-file-upload';
import { FullCalendarModule } from '@fullcalendar/angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    ObserversModule,
    MatIconModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    WebcamModule,
    NgxAnimationsModule,
    FileUploadModule,
    MatButtonModule,
    FlexLayoutModule,
    MatMenuModule,
    MatListModule,
    TypeaheadModule.forRoot(),
    PerfectScrollbarModule,
    RouterModule,
    FullCalendarModule,

  ],
  declarations: [],
  exports: [],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class SharedModule {}
