export class Properties {

  static VERSION = '2.1';
  
  static URL='https://2jmr807o1j.execute-api.us-east-1.amazonaws.com/'
  static APPNAME = 'PORTAL'
  static APITOKEN='82BBBB4884243BFE341E1D9C493C6A21F7167D7B6DF03B7AA626933EED6748EC'
  static APIKEY='2FB971D54EAE93C45B7EE69EC0A117422CCB6052'

  static RECORDARLOGIN='recordarUsuarioBienestar'
  static VAR_COCKIES_EMAIL='emailBienestarUsuario'
  static VAR_TOKEN_ADMIN='usuarioBienestarToken'
  static VAR_LOGIN='usuarioBienestarLogin'

  static urlAdmin="http://admin-mibienestar.colmayor.edu.co"
}
